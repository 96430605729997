import React, { useContext, useEffect } from 'react';
import Context, { InstallationContext } from '../../context';
import { useTranslation } from 'react-i18next';
import { Table, TableContainer, TableBody } from '@material-ui/core';
import Empty from '../../components/Empty';
import {
  AnswerSet,
  Attachment,
  InstallationStatus,
  PutAnswerPayload,
  QuestionSet,
} from '../../schemas';
import InstallationPlanQuestion from '../InstallationPlanQuestion';
import {
  CreateDeviationPayload,
  EditDeviationPayload,
} from '../../components/DeviationForm';
import {
  createDeviation,
  editDeviation,
  getDeviationsData,
} from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import { useGetToken } from '../../hooks/useGetToken';
import { uploadAttachments } from '../../helpers/upload-download';
import { API_TYPE, post } from '../../helpers/fetch';
import { useLocation } from 'react-router-dom';
import { useUpdateInstallationTaskAnswer } from '../../hooks/useInstallationTaskAnswer';

type QuestionListProps = {
  questionSets?: QuestionSet[];
  answers?: AnswerSet[];
  startDate?: string | null;
  targetDate?: string | null;
  networkNumber: string;
};

const InstallationPlanQuestionsList = (props: QuestionListProps): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const { updateIsLoading, updateErrorMessage } = useContext(Context);
  const [updateInstallationTaskAnswer] = useUpdateInstallationTaskAnswer();
  const { dispatch } = useContext(InstallationContext);
  const [getTokenFunction] = useGetToken();
  const isInstallationTaskPath = location.pathname.includes('installation-tasks');
  const { questionSets, answers, startDate, targetDate, networkNumber } = props;

  const questionSetToShow = questionSets?.find(
    (questionSet) => questionSet.questionSetId === 'PLAN'
  );

  useEffect(() => {
    const fetchDeviations = async () => {
      const accessToken = await getTokenFunction();
      const fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
      const filteredDeviations = fetchedDeviations?.filter(
        (deviation) =>
          deviation.installationWorkflowStatus === InstallationStatus.INSTALLER_ACCEPTED
      );
      dispatch({
        type: InstallationActionName.SET_DEVIATIONS,
        deviations: filteredDeviations,
      });
    };
    fetchDeviations();
  }, []);

  const onSaveAnswer = async (
    newAnswer: PutAnswerPayload,
    questionSequenceNumber: number
  ) => {
    updateIsLoading(true);
    const accessToken = await getTokenFunction();
    const files = newAnswer.value || [];
    const uploadFiles: File[] = [];
    const dbFiles: Attachment[] = [];
    if (Array.isArray(files)) {
      files.forEach((file: any) => {
        if (file instanceof File) {
          uploadFiles.push(file);
        } else {
          dbFiles.push(file);
        }
      });
    }

    try {
      const actualQuestionSequenceNumber =
        questionSequenceNumber < 10
          ? `0${questionSequenceNumber}`
          : questionSequenceNumber;

      newAnswer.value = true;

      if (Array.isArray(uploadFiles) && uploadFiles.length) {
        const attachments = await uploadAttachments({
          files: uploadFiles,
          networkNumber,
          questionSequenceNumber,
          jwtToken: accessToken,
        });
        newAnswer.value = [...dbFiles, ...(attachments || [])];
      }

      const updatedAnswer = await post(
        `v1/installations/${networkNumber}/answers/PLAN/${actualQuestionSequenceNumber}`,
        accessToken,
        API_TYPE.APPLICATION,
        newAnswer
      );
      updateInstallationTaskAnswer(updatedAnswer, 'PLAN', questionSequenceNumber);
    } catch (e) {
      console.error(e);
    }
    updateIsLoading(false);
  };

  const handleCreateDeviation = async (deviation: CreateDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);

    try {
      const createdDeviation = await createDeviation(
        accessToken,
        networkNumber,
        deviation
      );
      dispatch({
        type: InstallationActionName.ADD_DEVIATION,
        deviation: createdDeviation,
      });
      const fetchedDeviations = await getDeviationsData(accessToken, networkNumber);
      const filteredDeviations = fetchedDeviations.filter(
        (deviation) =>
          deviation.installationWorkflowStatus === InstallationStatus.INSTALLER_ACCEPTED
      );
      dispatch({
        type: InstallationActionName.SET_DEVIATIONS,
        deviations: filteredDeviations,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotCreateDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const handleEditDeviation = async (deviation: EditDeviationPayload) => {
    const accessToken = await getTokenFunction();
    updateIsLoading(true);
    try {
      const updatedDeviation = await editDeviation(accessToken, networkNumber, deviation);
      dispatch({
        type: InstallationActionName.EDIT_DEVIATION,
        deviation: updatedDeviation,
      });
    } catch (error) {
      updateErrorMessage({
        message: t('questionList.cannotEditDeviation'),
        error,
      });
    }
    updateIsLoading(false);
  };

  const renderQuestionList = (questionSet: QuestionSet) => {
    const { questions } = questionSet;
    const answerSets = answers?.find((answerSet) => answerSet.questionSetId === 'PLAN');
    const answerValues = answerSets?.answers || [];
    const questionListRow: JSX.Element[] = [];

    for (let index = 0; index < questionSet.questions.length; index++) {
      isInstallationTaskPath
        ? questionListRow.push(
            <InstallationPlanQuestion
              key={`${questionSet.questionSetId}-${index}`}
              question={questions[index]}
              answer={answerValues[index]}
              tableRowIndex={index}
              startDate={startDate ? startDate : null}
              targetDate={targetDate ? targetDate : null}
              saveAnswer={(newAnswer) => onSaveAnswer(newAnswer, index)}
              onCreateDeviation={handleCreateDeviation}
              onEditDeviation={handleEditDeviation}
              questionSequence={index}
            />
          )
        : questionListRow.push(
            <InstallationPlanQuestion
              key={`${questionSet.questionSetId}-${index}`}
              question={questions[index]}
              tableRowIndex={index}
              startDate={startDate ? startDate : null}
              targetDate={targetDate ? targetDate : null}
            />
          );
    }
    return questionListRow;
  };

  return (
    <>
      <div data-testid="installation-plan-questionsList">
        {questionSets && questionSets.length > 0 && questionSetToShow !== undefined ? (
          <>
            <TableContainer>
              <Table>
                <TableBody data-testid="installation-plan-question">
                  {renderQuestionList(questionSetToShow)}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Empty displayIcon={false} message={t('')}></Empty>
        )}
      </div>
    </>
  );
};

export default InstallationPlanQuestionsList;
